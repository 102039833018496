import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Review from "../util/review";

export default function Testimonials() {
  const settings = {
    dots: true,
    arrows: true,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    pauseOnHover: true,
  };
  return (
    <section className="pl-3 pr-3">
      <Container>
        <Row>
          <Col>
            <h1 className="text-center pb-2">Testimonials</h1>
            <Slider {...settings}>
              <Review
                reviewer="Samantha"
                reviewerRole="Parent"
                review="My expectations were exceeded! I knew my daughter was more than capable, but the teacher helped to bring
        forward his potential."
              />
              <Review
                reviewer="Jasman"
                reviewerRole="Parent"
                review="My son has been going to Great Minds for the past 5 years and he loves it. His confidence level and quality of
        work in math and English have improved tremendously."
              />
              <Review
                reviewer="Emma"
                reviewerRole="Parent"
                review="I don’t even know where to begin as I have been nothing but impressed by the service and results of the tutors
        at Great Minds. The service received was professional, accommodating and more than I expected. My daughter’s
        grades improved drastically within months, and much of it is attributed to the system they have in place in
        assessment and creating a study plan catered specifically FOR HER. I highly recommend Great Minds, not only will
        your child’s grades improve, but they will be equipped with tools needed for post secondary"
              />
              <Review
                reviewer="Nina"
                reviewerRole="Parent"
                review="Both my boys are tutored by Great Minds for math and physics. Their mentor is punctual and flexible with her
        time. She pushes the kids out of their comfort zone and assigns homework for both my boys. I really appreciate
        the detailed summary she gives after each lesson including what they worked on as well as their behaviour
        (attention span, frustrations etc.). Not only that, the mentor is willing to help outside of tutoring hours as
        well (highly appreciate this attitude, she really cares about their success). I would definitely recommend Great
        Minds Academy"
              />
              <Review
                reviewer="Lilly"
                reviewerRole="Parent"
                review="The staff is wonderful, friendly, informative, supportive and always willing to help and assist in any way
        possible."
              />
              <Review
                reviewer="Dave"
                reviewerRole="Parent"
                review="Just to let you know that Nick is GREAT and my son is really enjoying working with him"
              />
              <Review
                reviewer="Sonia"
                reviewerRole="Parent"
                review="My daughter has had some sessions with Lindsay. She is terrific and she plans to continue working with her once
        per week."
              />
            </Slider>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

/*
 
              */
