import React from "react";

export default function Review({ reviewer, reviewerRole, review }) {
  return (
    <div className="text-center">
      <p>"{review}"</p>
      <h3>~ {reviewer}</h3>
      <p class="lead">{reviewerRole}</p>
    </div>
  );
}
