import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import APlusIcon from "../../images/svg/a-plus.inline.svg";

export default function TutoringHighlights() {
  return (
    <section id="tutoring-highlights">
      <Container>
        <Row className="text-center">
          <Col xs={12} md={4}>
            <h2>Customized Programs</h2>
            <p>
              Whether your child is working to maintain good grades, looking for
              enrichment, or trying to catch up to grade level, our teachers
              will create an individual learning plan for their success.
              Memorization has its place, but it is better to have a 100%
              understanding of concepts.
            </p>
          </Col>
          <Col xs={12} md={4}>
            <h2>Child-Centred</h2>
            <p>
              We provide a positive learning experience for your child. This
              means meeting your child where they’re at, cultivating a strong
              relationship with them, understanding their personal learning
              style, and actively working to build their confidence while they
              learn.
            </p>
          </Col>
          <Col xs={12} md={4}>
            <h2>Parent-Teacher Interaction</h2>
            <p>
              Provide encouragement, assistance and feedback to the students and
              parents in a timely and appropriate manner. Provide a timely
              opportunity to any parent who wishes to discuss any concerns they
              may have about their child’s experience.
            </p>
          </Col>
        </Row>
        <Row className="text-center">
          <Col xs={12} md={4}>
            <h2>Student Connection</h2>
            <p>
              The positive connection between the tutor and your child is
              crucial; this connection is strongest when tutors work in small
              groups and give parents instant feedback.
            </p>
          </Col>
          <Col xs={12} md={4} className="text-center p-3">
            <APlusIcon />{" "}
          </Col>
          <Col xs={12} md={4}>
            <h2>Professional Tutors</h2>
            <p>
              Certified teachers, educational assistants and university graduates with extensive tutoring experience.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
