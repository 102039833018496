import React from "react";
import BaseLayout from "../components/layouts/baseLayout";
import WelcomeHeroImage from "../components/home/welcomeHeroImage";
import FeaturedPreschool from "../components/home/featuredPreschool";
import ProgramHighlights from "../components/home/programHighlights";
import PreschoolClassesOffered from "../components/home/preschoolClassesOffered";
import FeaturedTutoring from "../components/home/featuredTutoring";
import TutoringHighlights from "../components/home/tutoringHighlights";
import TutoringClassesOffered from "../components/home/tutoringClassesOffered";
import Testimonials from "../components/home/testimonials";
import { COMPANY_NAME } from "../constants/constants";

export default function IndexPage({ data }) {
  return (
    <BaseLayout pageTitle={COMPANY_NAME}>
      <WelcomeHeroImage />
      <FeaturedPreschool />
      <ProgramHighlights />
      <PreschoolClassesOffered />
      <FeaturedTutoring />
      <TutoringHighlights />
      <TutoringClassesOffered />
      <Testimonials />
    </BaseLayout>
  );
}
