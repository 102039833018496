import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

export default function Studying({ className }) {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "studying.jpg" }) {
        childImageSharp {
          fluid(fit: INSIDE) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return <Img fluid={data.file.childImageSharp.fluid} className={className} />;
}
