import React from "react";
import { Container, Row, Col } from "react-bootstrap";

export default function FeaturedTutoring() {
  return (
    <section id="featured-tutoring">
      <Container>
        <Row>
          <Col>
            <h1 className="text-center pb-2">Tutoring</h1>
            <p>
              Great Minds Academy is an after-school enrichment program in math,
              english and science for children ages 4 and up. The program
              consists of weekly classes and homework assignments. Teaching and
              learning takes place in a small group environment with emphasizes
              on developing confidence, creative problem solving and preparing
              students for higher education. Our high-quality teaching, and
              academic management is focused on improving student achievement,
              and creating a safe, clean and comfortable environment. All our
              teachers possess an appropriate qualification and have valuable
              prior teaching experience.
            </p>
            <p>
              We evaluate your child’s strengths, needs, beliefs and behaviors,
              so we know exactly where to start. Based on the results of initial
              evaluation, teachers design a customized learning program at
              either below, at or above the students’ grade level. Our expert
              teachers guide, instruct and adapt to your child’s skill level and
              pace. As students progress through the program, each worksheet
              becomes one step more advanced than the one before, ensuring your
              child fully absorbs and learns each concept before moving on to
              the next.
            </p>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
