import React from "react";
import { Container, Row, Col, CardDeck } from "react-bootstrap";
import ClassCard from "./classCard";
import ArtDeskImage from "../images/artDesk";
import StudyingImage from "../images/studying";
export default function TutoringClassesOffered(props) {
  return (
    <section>
      <Container>
        <Row>
          <Col>
            <CardDeck>
              <ClassCard
                // title="Preschool to K9"
                btnText="Preschool and Junior High"
                btnLink="/tutoring/preschool-to-k9"
                image={<ArtDeskImage />}
              />
              <ClassCard
                // title="Highschool"
                btnText="Senior High"
                btnLink="/tutoring/highschool"
                image={<StudyingImage />}
              />
            </CardDeck>
          </Col>
        </Row>
      </Container>
    </section>
  );
}
