import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Container, Row, Col, Button } from "react-bootstrap";
import styled from "styled-components";
import GatsbyBackgroundImage from "gatsby-background-image";

const ChildBackgroundImage = styled(GatsbyBackgroundImage)`
  width: 100%;
  height: 100vh;
  opacity: 1 !important;
  background-size: cover;
  background-size: cover;
`;

const BackgroundSection = ({ className }) => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(
          relativePath: { eq: "child-with-hammer-and-blocks.jpg" }
        ) {
          childImageSharp {
            fluid(quality: 90) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  );
  // Set ImageData.
  const imageData = data.desktop.childImageSharp.fluid;

  return (
    <ChildBackgroundImage Tag="section" className={className} fluid={imageData}>
      <Container fluid className="h-100 p-0">
        <Row className="align-items-center h-100 p-0">
          <Col
            className="my-auto text-center rounded pb-sm-5 pt-sm-5 p-3 text-dark bg-white border "
            sm={{ span: 5, offset: 5 }}
          >
            <h1>Welcome to Great Minds Academy</h1>
            <p className="lead">We Learn We Grow</p>
            <p className="mb-2 text-left">
              Great Minds Preschool is a fully licensed child care facility with
              certified professional instructors. It is our mission to provide
              reputable, quality child care and develop long-term relationships
              with families in our community. We are specialized in early
              education and provide academic tutoring for grades K-12.
            </p>
            <Button href="#featured-preschool" size="lg" variant="primary mr-2">
              Preschool
            </Button>
            <Button href="#featured-tutoring" size="lg" variant="primary">
              Tutoring
            </Button>
          </Col>
        </Row>
      </Container>
    </ChildBackgroundImage>
  );
};

const WelcomeHeroImage = styled(BackgroundSection)`
  width: 100%;
  height: 100vh;
  background-size: cover;
`;

export default WelcomeHeroImage;
